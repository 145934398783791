import * as React from "react";
import Footer from "../components/Footer";
import Banner from "../components/home/Banner";
import GetAQuote from "../components/home/GetAQuote";
import WhoWeAre from "../components/home/WhoWeAre";
import Testimonials from "../components/home/Testimonials";
import WhatWeDo from "../components/home/WhatWeDo";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";

// markup
const IndexPage = () => {
  useEffect(() => {
    const message = "You only see me once.";
  }, []);

  return (
    <main className="container max-w-[1920px] mx-auto flex flex-col justify-between">
      {/* <div className="bg-[url('../images/images/banner_image.jpg')] bg-cover h-screen flex flex-col"></div> */}
      <Banner />
      <WhatWeDo />
      <Testimonials />

      <WhoWeAre />
      <GetAQuote />
      <Footer />
    </main>
  );
};

export default IndexPage;
