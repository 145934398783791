import React from "react";
import Slider from "react-slick";
import ArrowLeftIcon from "../../images/icons/arrow-left.svg";
import ArrowRightIcon from "../../images/icons/arrow-right.svg";

export default function Testimonials() {
  const sliderRef = React.useRef();

  const testimonialsList = [
    {
      title: "Data Sources",
      Narration:
        "We are very experienced when it comes to identifying relevant data structures, underlying business logic, data relationships, and impact due to customizations. This first step, often trivialized, is the foundation of building great data analytics.",
      Note: "In our experience documentation is either unavailable, sketchy or not updated with the many changes data sources are subject to.",
      serial: "1",
    },
    {
      title: "Load & Transform",
      Narration:
        "A common theme across all our engagements has been of managing multiple data sources, for example HR, ERP, spreadsheets like XL & Google, cloud solutions - any digital format really (including some unstuctured sources). ",
      Note: "We have built the muscles to manage the heavy lifting to get this done well.",
      serial: "2",
    },
    {
      title: "Data Modelling",
      Narration:
        "What enables rapid report additions, changes, multiple dashboards, ad-hoc reporting and much of what we deliver, is based on how well data is modelled. We identify dimensions, facts, and work at the semantic layer, for example, to create the reporting fabric your business needs.",
      Note: "Our data models support your data to 'talk to the business'",
      serial: "3",
    },
    {
      title: "Reporting",
      Narration:
        "Our core value is that we deliver a framework that is designed to deliver incremental analytics. Coupled with our consulting, our dashboards and reports support discovery, enabling businesses to continuously discover new needs, and even enable self-service for ad-hoc needs.",
      Note: "We look at audit reports to continuously improve the value that you derive.",
      serial: "4",
    },
  ];

  const settings = {
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: true,
      //     dots: true,
      //   },
      // },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRef.current && sliderRef.current?.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current && sliderRef.current?.slickNext();
  };

  return (
    <div className="overflow-hidden">
      {}
      <div
        id="testimonials-container"
        className="bg-[#0426A0] text-white p-6 md:p-16 flex justify-between items-end"
      >
        <div className="flex-1 mr-12">
          <div className="text-2xl md:text-6xl font-light">
            <div>
              we deliver what <br />
              we promise
              <span className="text-[#57B847] md:text-7xl leading-7">.</span>
            </div>
          </div>

          {/* <div className="md:text-xl mt-4 md:mt-8 md:leading-loose">
            With nearly 20 years of collective knowledge, we
            <br className="hidden md:block" />
            use emerging technologies to solve problems
            <br className="hidden md:block" />
            and shape the behaviors of tomorrow
          </div> */}
        </div>
        <div id="arrows" className="flex">
          <img
            id="testimonials-prev"
            src={ArrowLeftIcon}
            className="w-7 md:w-16 mr-6 cursor-pointer"
            onClick={handlePrevClick}
          />
          <img
            id="testimonials-next"
            className="w-7 md:w-16 md:mr-6 cursor-pointer"
            src={ArrowRightIcon}
            onClick={handleNextClick}
          />
        </div>
      </div>
      <div id="testimonial-container">
        <Slider
          ref={sliderRef}
          {...settings}
          id="testimonials"
          className="bg-[#0426A0]"
        >
          {testimonialsList.map((testimonial, index) => (
            <div
              key={`testimonial-${index}`}
              className="p-12 text-white border-2 border-[#1938A8] flex flex-col justify-between slick-custom-item"
            >
              <div className="h-[450px] md:h-[420px] flex flex-col">
                <div className="flex-1">
                  <div className="text-4xl mb-6">{testimonial.title}</div>

                  {testimonial.Narration}
                  <br />
                  <br />
                  {testimonial.Note}
                </div>
                <div>
                  <span className="text-4xl">
                    {testimonial.serial}/{testimonialsList.length}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {}
    </div>
  );
}
