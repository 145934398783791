import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import ICONS from "../../utils/icons";
import IMAGES from "../../utils/images";

export default function WhatWeDo() {
  return (
    <div>
      {/* What we do */}
      <section className="p-6 md:p-16 bg-white" id="what-we-do">
        <div className="text-5xl mb-12 font-semibold">
          what we do<span className="text-[#57B847] text-7xl leading-7">.</span>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex items-start min-w-[30%]">
            <div className="text-xl">
              20+ years of <br />
              experience
            </div>
            <div className="ml-12 mt-4 w-32 h-0.5 bg-gray-200" />
          </div>
          <div className="flex-1 mt-6 md:mt-0 md:pl-4 md:text-2xl leading-loose">
            As exciting, ‘cutting-edge’, and ‘sexy’ as Data Analytics sounds,
            this field is now incredibly complex – extracting meaningful
            insights from data is a super-specialist business. And like heart
            surgery, not something you will want to build in-house capacity – or
            really build efficiently even if you wanted to.
            <p className="mt-6">
              Even the best analytics frameworks are mere tools, like scalpels.
              You need a dedicated, co-ordinated team, like us, to deliver real
              results affordably.
            </p>
          </div>
        </div>
      </section>
      <div className="md:pb-24 bg-white">
        <div className="p-6 md:p-0 md:grid grid-cols-3 md:grid-flow-col md:ml-24">
          <AniLink fade to="/analytics_as_a_service">
            <img src={IMAGES.AnalyticServiceImage} className="w-full" />
            <div className="pr-7">
              <div className="text-2xl mt-8">Analytics as a Service</div>
              <div className="text-base leading-loose mt-4">
                We operate on a fixed per-month fee for all your data analytics
                need - unlimited reports, modifications and data storage. The
                entire engagement is localized to your needs - no mass market,
                no 'templates', no generalization.
              </div>
            </div>
            <img src={ICONS.RightArrow} className="pt-6" />
          </AniLink>
          <div className="mt-12 md:mt-0">
            <img
              src={IMAGES.BusinessEventImage}
              style={{ objectFit: "cover" }}
              className="w-full"
            />
            <div className="pr-6">
              <div className="text-2xl mt-8">Partners as a Service</div>
              <div className="text-base leading-loose mt-4">
                Because we have our skin in the game with a fixed-fee model, you
                are assured of timely, relevant, and high quality analytics.
                With us, you get business partners and not vendors. No wild,
                expensive and wasteful recommendations from us!
              </div>
            </div>
            <img src={ICONS.RightArrow} className="pt-6" />
          </div>
          <div className="mt-12 md:mt-0">
            <img src={IMAGES.NetworkImage} className="w-full" />
            <div className="pr-6">
              <div className="text-2xl mt-8">Technology as a Service</div>
              <div className="text-base leading-loose mt-4">
                Our fixed-fee Opex model is a no brainer. No time sheets, scope
                or change management, and no need for you to have a dedicated
                team to approve complex documents. And you get to talk to real
                humans, supported by Slack®
              </div>
            </div>
            <img src={ICONS.RightArrow} className="pt-6" />
          </div>
        </div>
      </div>
      {/* End What we do */}
    </div>
  );
}
