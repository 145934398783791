import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";
import ArrowLeftIcon from "../../images/icons/arrow-left.svg";
import ArrowRightIcon from "../../images/icons/arrow-right.svg";
import IMAGES from "../../utils/images";
import TopbarDark from "../TopbarDark";

export default function Banner() {
  const sliderRef = React.useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRef.current && sliderRef.current?.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current && sliderRef.current?.slickNext();
  };

  return (
    <section className="flex-1 relative">
      <div className="absolute z-10 w-full">
        <TopbarDark />
      </div>
      <div className="hidden md:block text-white p-12 absolute top-[50%] z-10">
        <div className="origin-left -rotate-90">enquiry@thinklokal.com</div>
      </div>
      <div id="arrows" className="z-10 absolute bottom-[38px] right-[530px]">
        <div className="flex">
          <img
            id="prev"
            alt="banner"
            src={ArrowLeftIcon}
            className="mr-6 cursor-pointer"
            onClick={handlePrevClick}
          />
          <img
            id="banner-next"
            alt="next"
            className="cursor-pointer"
            src={ArrowRightIcon}
            onClick={handleNextClick}
          />
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        <div className="flex w-full h-full relative">
          <StaticImage
            className="h-screen"
            objectFit="cover"
            src="https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fimages%2Fthinklokal-banner-03.jpg?alt=media&token=fb37eaad-ce6c-4ed0-b6e8-d06a631d634d"
          />
          <div className="absolute bottom-0 right-0 z-10">
            <div className="md:px-24 py-12 text-white text-3xl leading-tight font-light">
              <div className="w-[100vw] md:w-[600px] p-6 text-center md:text-left mb-[80px]">
                <div className="hidebreak-sm">
                  Most businesses extract and exploit <br />
                  very limited insights from their data.
                  <br />
                  And it's not due to lack of trying.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full h-full relative">
          <StaticImage
            className="h-screen"
            src="https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fimages%2Fthinklokal-banner-02.jpg?alt=media&token=53b3d7db-c263-463f-b4c4-9e8db096e52e"
          />
          <div className="absolute bottom-0 right-0 z-10">
            <div className="md:px-24 py-12 text-white text-3xl leading-tight font-light">
              <div className="w-[100vw] md:w-[600px] p-6 text-center md:text-left mb-[80px]">
                <div className="hidebreak-sm">
                  Impactful data analysis is beyond tools.
                  <br />
                  We come in as partners, bringing our <br />
                  expertise to extract value from data.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full h-full relative">
          <StaticImage
            className="h-screen"
            objectFit="cover"
            src="https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fimages%2Fthinklokal-banner-01.jpg?alt=media&token=1147ee77-4997-4883-a0fc-3c1817a40a2e"
          />
          <div className="absolute bottom-0 right-0 z-10">
            <div className="md:px-24 py-12 text-white text-3xl leading-tight font-light">
              <div className="w-[100vw] md:w-[600px] p-6 text-center md:text-left mb-[80px]">
                <div className="hidebreak-sm">
                  Your business decisions get sharper,
                  <br />
                  enabling focussed and timely
                  <br />
                  deployment of resources.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
}
